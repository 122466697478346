import React, { useState, useEffect, useRef } from "react";
import ModalToggleButton from "./ModalToggleButton";
import Modal from "./Modal";

const PricingSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };
  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Flexible Pricing Plans
          </h2>
          <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
            Designed for sales teams like yours
          </p>
        </div>
        <div className="space-y-8 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0">
          <PricingCard
            title="Standard Pricing"
            description="Perfect for individual sales professionals and small teams looking to enhance their sales workflow"
            price="$99"
            period="per month per user"
            features={[
              "Connect with Email & LinkedIn",
              "No setup or hidden fees",
              "For teams of 2-10 members",
              "Priority support for outreach",
              "Free updates to new features",
            ]}
          />
          <PricingCard
            title="Enterprise Solution"
            description="Customized pricing available for larger organizations requiring tailored solutions to fit their complex needs ."
            // price="$99"
            // period="/month"
            features={[
              "Access control for each member",
              "Personalized setup with Email & LinkedIn",
              "Sync or import from your CRM",
              "For teams of 10+ members",
              "Priority support for outreach",
              "Free updates to new features",
            ]}
          />
        </div> 
      </div>
    </section>
  );
};

const PricingCard = ({ title, description, price, period, features }) => {
  return (
    <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
      <h3 className="mb-4 text-2xl font-semibold">{title}</h3>
      <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
        {description}
      </p>
      <div className="flex justify-center items-baseline my-8">
        <span className="mr-2 text-5xl font-extrabold">{price}</span>
        <span className="text-gray-500 dark:text-gray-400">{period}</span>
      </div>
      <ul role="list" className="mb-8 space-y-4 text-left">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center space-x-3">
            <svg
              className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span>{feature}</span>
          </li>
        ))}
      </ul>

      {/* <button
        style={{ fontSize: "16px", backgroundColor: "#51C185" }}
        className="block text-white  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        type="button"
      >
        Get started
      </button> */}
    </div>
  );
};

export default PricingSection;
