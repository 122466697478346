import React, { useEffect, useRef, useState } from "react";

const Video = ({ featuresRef }) => {
  return (
    <section id="product" ref={featuresRef} className="py-14 lg:py-20">
      <div className="w-full mx-auto text-center">
        <h6 className="tracking-tight text-gray-900 sm:text-4xl">
          Discover how TrillionSale revolutionises outbound sales through
          automation
        </h6>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "4%",
          marginBottom: "8%",
        }}
      >
        <iframe
          style={{ borderRadius: "10px" }}
          width="1400"
          height="700"
          src="https://www.youtube.com/embed/3Umuc8VlwWc?si=88w9Pn4BXnqZA6Lz"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
    </section>
  );
};

export default Video;
