import React from "react";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import abc2 from "../assets/1.png";
import abc1 from "../assets/email-marketing.png";
import abc from "../assets/linkedin.png";
import down1 from "../assets/down1.png";

const Features = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      {/* <div className="max-w-xl mx-auto text-center">
        <h2 className="text-3xl font-bold sm:text-4xl text-gray-900">
          <span className="relative inline-block">
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                  id="18302e52-9e2a-4c8e-9550-0cbb21b38e55"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect
                fill="url(#18302e52-9e2a-4c8e-9550-0cbb21b38e55)"
                width="52"
                height="24"
              />
            </svg>
          </span>
          <span className="relative">
            {" "}
            Current state of sales - without Trillionsale
          </span>
        </h2>
      </div> */}

      <div className="max-w-xl mx-auto text-center">
        <h2
          style={{ marginBottom: "48px" }}
          className="text-3xl font-bold sm:text-4xl text-gray-900"
        >
          Key Features
        </h2>
      </div>
      <div className="grid gap-3 row-gap-4 sm:grid-cols-2 lg:grid-cols-3 ">
        <div
          className="
        // flex flex-col justify-between p-5 border rounded shadow-sm
        "
        >
          <div>
            <div
              className="
              flex items-center justify-center w-16 h-16 mb-4  
              rounded-full
             bg-indigo-50"
              style={{ padding: "10px" }}
            >
              <img
                className=""
                src={abc}
                alt="logo"
                // style={{ width: "80%", height: "100%" }}
              />
            </div>
            <h6 className="mb-2 font-semibold leading-5">
              LinkedIn Automation Mastery
            </h6>
            <p className="mb-3 text-sm text-gray-900">
              Our platform specialises in LinkedIn outreach, automating
              connection requests, follow-ups, and personalised messaging,
              ensuring you engage the right prospects efficiently.
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
          <div>
            <div
              className="
              flex items-center justify-center w-16 h-16 mb-4  
              rounded-full
             bg-indigo-50"
              style={{ padding: "10px" }}
            >
              <img
                className=""
                src={abc1}
                alt="logo"
                // style={{ width: "80%", height: "100%" }}
              />
            </div>
            <h6 className="mb-2 font-semibold leading-5"> Email Integration</h6>
            <p className="mb-3 text-sm text-gray-900">
              Seamlessly integrate your email and LinkedIn interactions to
              personalise outbound campaigns to keep your prospects engaged
            </p>
          </div>
        </div>

        <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
          <div>
            <div
              className="
              flex items-center justify-center w-16 h-16 mb-4  
              rounded-full
             bg-indigo-50"
              style={{ padding: "10px" }}
            >
              <img
                className=""
                src={abc2}
                alt="logo"
                // style={{ width: "80%", height: "100%" }}
              />
            </div>
            <h6 className="mb-2 font-semibold leading-5">
              Real-Time Monitoring and Recommendations{" "}
            </h6>
            <p className="mb-3 text-sm text-gray-900">
              Stay ahead by leveraging AI-driven insights that identify and
              recommend actionable leads in real time.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
