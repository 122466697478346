import React from "react";

const ModalToggleButton = ({toggleModal }) => {

  return (
    <button
      onClick={toggleModal}
      style={{
        fontSize: "16px",
        backgroundColor: "#51C185",
        padding: "10px 20px",
        display: "inline-block",
      }}
      className="text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      type="button"
    >
      Book Demo
    </button>
  );
};


export default ModalToggleButton;
