import React from "react";
import time from "../assets/time.png";
import monitoring from "../assets/monitoring.png";
import profit from "../assets/profit.png";
import inc from "../assets/adv.png";
import recommend from "../assets/recommend.png";
import ai from "../assets/artificial-intelligence.png";

const Works = () => {
  return (
    <section
      id="features"
      className="bg-white text-white flex justify-center items-center"
    >
      <div className="max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="max-w-xl mx-auto text-center">
          <h2 className="text-3xl font-bold sm:text-4xl text-gray-900">
            How TrillionSale Works
          </h2>
        </div>

        <div
          className="grid gap-3 row-gap-4 sm:grid-cols-2 lg:grid-cols-3 "
          style={{ marginTop: "3%" }}
        >
          <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
            <div>
              <div
                className="
              flex items-center justify-center w-16 h-16 mb-4  
              rounded-full
             bg-indigo-50"
                style={{ padding: "10px" }}
                data-te-animation-init
                data-te-animation-reset="true"
                data-te-animation="[slide-right_1s_ease-in-out]"
              >
                <img
                  className=""
                  src={monitoring}
                  alt="logo"
                  // style={{ width: "80%", height: "100%" }}
                />
              </div>
              <h6 className="mb-2 font-semibold leading-5 text-gray-900">
                Monitor
              </h6>
              <p className="mb-3 text-sm text-gray-900">
                Track key LinkedIn and web activities and trends that signal
                buying intent in real-time.
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
            <div>
              <div
                className="
              flex items-center justify-center w-16 h-16 mb-4  
              rounded-full
             bg-indigo-50"
                style={{ padding: "10px" }}
              >
                <img
                  className=""
                  src={recommend}
                  alt="logo"
                  // style={{ width: "80%", height: "100%" }}
                />
              </div>
              <h6
                className="mb-2 font-semibold leading-5 text-gray-900
"
              >
                {" "}
                Recommend
              </h6>
              <p className="mb-3 text-sm text-gray-900">
                Get a daily list of curated high-potential accounts and
                essential contacts
              </p>
            </div>
          </div>

          <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
            <div>
              <div
                className="
              flex items-center justify-center w-16 h-16 mb-4  
              rounded-full
             bg-indigo-50"
                style={{ padding: "10px" }}
              >
                <img
                  className=""
                  src={ai}
                  alt="logo"
                  // style={{ width: "80%", height: "100%" }}
                />
              </div>
              <h6 className="mb-2 font-semibold leading-5 Performance gaps text-gray-900">
                Automate Outreach{" "}
              </h6>
              <p className="mb-3 text-sm text-gray-900">
                Initiate automated, customized LinkedIn and email communications
                to maximize engagement without manual effort
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Works;
