// LandingPage.js
import React, { useRef } from "react";
import Hero from "./Hero";
import LogoClouds from "./LogoClouds";
import Features from "./Features";
import Benefits from "./Benefits";
import Works from "./Works";
import Testimonials from "./Testimonials";
import Footer from "./Footer";
import Demo from "./Demo";
import Video from "./Video";
import PricingSection from "./PricingSection";

const LandingPage = () => {
  const featuresRef = useRef();

const scrollToFeatures = () => {
  if (featuresRef.current) {
    featuresRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest", 
    });
  }
};


  return (
    <div>
      <Hero scrollToFeatures={scrollToFeatures} />
      <Video featuresRef={featuresRef} />
      <Features />
      <Benefits featuresRef={featuresRef} />
      <Works />
      <LogoClouds />
      {/* <Demo /> */}

      <PricingSection />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default LandingPage;
