import React from "react";
import marketing from "../assets/marketing.png";
import profit from "../assets/profit.png";
import inc from "../assets/adv.png";
import data from "../assets/acc.png";

const Benefits = ({ featuresRef }) => {
  return (
    <section
      id="features"
      ref={featuresRef}
      className="bg-white text-white flex justify-center items-center"
    >
      <div className="max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="max-w-xl mx-auto text-center">
          <h2 className="text-3xl font-bold sm:text-4xl text-gray-900">
            Key Benefits
          </h2>
        </div>

        <div
          className="grid gap-3 row-gap-4 sm:grid-cols-2 lg:grid-cols-3 "
          style={{ marginTop: "3%" }}
        >
          <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
            <div>
              <div
                className="
              flex items-center justify-center w-16 h-16 mb-4  
              rounded-full
             bg-indigo-50"
                style={{ padding: "10px" }}
                data-te-animation-init
                data-te-animation-reset="true"
                data-te-animation="[slide-right_1s_ease-in-out]"
              >
                <img
                  className=""
                  src={marketing}
                  alt="logo"
                  // style={{ width: "80%", height: "100%" }}
                />
              </div>
              <h6 className="mb-2 font-semibold leading-5 text-gray-900">
                Amplified Outreach
              </h6>
              <p className="mb-3 text-sm text-gray-900">
                Engage more prospects with automated, personalised outreach
                campaigns, designed to cut through the noise and connect
                directly with decision-makers.
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
            <div>
              <div
                className="
              flex items-center justify-center w-16 h-16 mb-4  
              rounded-full
             bg-indigo-50"
                style={{ padding: "10px" }}
              >
                <img
                  className=""
                  src={data}
                  alt="logo"
                  // style={{ width: "80%", height: "100%" }}
                />
              </div>
              <h6
                className="mb-2 font-semibold leading-5 text-gray-900
"
              >
                {" "}
                Enhanced Lead Quality
              </h6>
              <p className="mb-3 text-sm text-gray-900">
                Our algorithms ensure you only spend time on leads with the
                highest potential for conversion.
              </p>
            </div>
          </div>

          <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
            <div>
              <div
                className="
              flex items-center justify-center w-16 h-16 mb-4  
              rounded-full
             bg-indigo-50"
                style={{ padding: "10px" }}
              >
                <img
                  className=""
                  src={inc}
                  alt="logo"
                  // style={{ width: "80%", height: "100%" }}
                />
              </div>
              <h6 className="mb-2 font-semibold leading-5 Performance gaps text-gray-900">
                Speed to Lead{" "}
              </h6>
              <p className="mb-3 text-sm text-gray-900">
                Outpace competitors with faster, more effective lead engagement,
                capturing interest while it's hot.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
