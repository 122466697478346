// Hero.js
import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { WhatsappIcon } from "react-share";
import logo from "../assets/newLogo1.png";
import ModalToggleButton from "./ModalToggleButton";
import Modal from "./Modal";

const textStyle = {
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  WebkitFontSmoothing: "antialiased",
  MozOsxFontSmoothing: "grayscale",
};

const navigation = [
  { name: "Product", href: "#product" },
  { name: "Features", href: "#features" },
  { name: "Blogs", href: "/blog" },
];

const Hero = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [scrollBackground, setScrollBackground] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isModalOpen, setIsModalOpen] = useState(false);

   const toggleModal = () => {
     setIsModalOpen((prev) => !prev);
   };


  const scrollToSection = (sectionId) => {
    if (sectionId === "product") {
      const iframeSection = document.getElementById("youtube-iframe");
      if (iframeSection) {
        const offsetTop = iframeSection.offsetTop - 100;
        window.scrollTo({ top: offsetTop, behavior: "smooth" });
      }
    } else {
      const section = document.getElementById(sectionId);
      if (section) {
        const offsetTop = section.offsetTop - 200;
        window.scrollTo({ top: offsetTop, behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrollBackground(true);
      } else {
        setScrollBackground(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerStyle = {
    backgroundColor: "#51C185",
    transition: "background-color 0.3s ease",
  };

  return (
    <div className="">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          aria-label="Global"
          style={{ width: "100%" }}
          className={
            scrollBackground
              ? "bg-white flex items-center justify-between p-6 lg:px-8 fixed"
              : "flex items-center justify-between p-6 lg:px-8 fixed"
          }
        >
          <div className="flex lg:flex-1">
            <a href="#" className="">
              <img
                className=""
                src={logo}
                alt="logo"
                style={{ width: windowWidth >= 1024 ? "30%" : "40%" }}
              />
            </a>
          </div>

          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          {windowWidth >= 1024 ? (
            <div
              className=" flex lg:gap-x-12 items-center justify-center lg:flex-1"
              style={{ marginRight: "3%" }}
            >
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-l font-semibold leading-6 text-gray-900"
                  target={item.href.startsWith("https") ? "_blank" : "_self"}
                  rel={
                    item.href.startsWith("https") ? "noopener noreferrer" : ""
                  }
                  onClick={() => scrollToSection(item.href.slice(1))}
                >
                  {item.name}
                </a>
              ))}
            </div>
          ) : null}

          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <div className="flex items-center justify-center gap-x-6">
              <ModalToggleButton toggleModal={toggleModal} />
            </div>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">TrillionSale</span>
                <img className="h-8 w-auto" src={logo} alt="" />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-lg font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      onClick={() => scrollToSection(item.href.slice(1))}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="https://calendly.com/mehak_trillionsale/meet-trillionsale?back=1&month=2024-02"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    style={{ backgroundColor: "#51C185" }}
                  >
                    Book Demo
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div
          className="flex flex-col items-center justify-center gap-8"
          style={{ marginTop: "8%" }}
        >
          <div className="hidden sm:mb-8 sm:flex sm:justify-center"></div>
          <div className="flex items-center justify-center space-y-8">
            <div className="text-center">
              <h1
                className="text-4xl font-bold tracking-tight sm:text-6xl"
                style={{
                  fontSize: "64px",
                  color: "#51C185",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                TrillionSale
              </h1>

              <div style={{ marginTop: "10px" }}>
                <h6 className="tracking-tight text-gray-900 sm:text-4xl">
                  Triple Your Meetings with TrillionSale’s Outreach Automation
                </h6>
              </div>

              <p
                className="mt-6 text-lg leading-8 text-gray-600"
                style={{ fontSize: "20px" }}
              >
                Supercharge your sales efforts with our LinkedIn-focused
                automation,
                <br />
                securing 3x more meetings before your competitors even make a
                move.
              </p>
              <div className="mt-4 flex items-center justify-center gap-x-6">
                <ModalToggleButton toggleModal={toggleModal} />
              </div>

              <Modal isOpen={isModalOpen} toggleModal={toggleModal} />
            </div>
          </div>
        </div>

        {/* <a
          href="https://wa.me/918708789154?text=Hello%21%20I%20am%20interested%20in%20learning%20more%20about%20TrillionSale"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            position: "fixed",
            bottom: "25px",
            right: "25px",
            zIndex: 1,
          }}
        >
          <WhatsappIcon size={52} round={true} />
        </a> */}

        {/* <img src={gifImage} alt="Example GIF" /> */}

        {/* <div
          id="youtube-iframe"
          className="embed-responsive embed-responsive-16by9 relative w-full overflow-hidden"
          style={{
            paddingTop: "45%",
            marginTop: "8%",
            width: "80%",
            marginLeft: "10%",
          }}
        >
          <iframe
            className="embed-responsive-item absolute bottom-0 left-0 right-0 top-0 h-full w-full rounded-lg"
            src="https://www.youtube.com/embed/w0VyRHUAqfw?si=R8PN4HL8R8TeSHjU"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
      
        </div> */}
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="px-4 py-2 text-white rounded-md bg-gradient-to-r from-[#FAA746] to-[#5580FF]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
